import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 8-8-8\\@75% 1RM`}</p>
    <p>{`&`}</p>
    <p>{`Hamstring Curls (on ball or foam roller)`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`5-Thrusters (95/65)`}</p>
    <p>{`5-Front Squats`}</p>
    <p>{`5-Power Cleans.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Thursday is our Annual Free Thanksgiving Day WOD at 9:00am at
The Ville! Both gyms will be closed other than the 9:00am Ville class
and the 6am class at East on Friday will be cancelled. All other classes
are on as scheduled.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      